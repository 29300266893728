import React from 'react';
import {
  Edit,
  TextInput,
  SimpleForm,
  SelectInput,
  FileField,
  FileInput,
  RadioButtonGroupInput
} from 'react-admin';

const ExerciseTitle = ({ record }) => {
  return <span>Exercise: {record ? record.title : ''}</span>;
};

export const ExerciseEdit = (props) => (
  <Edit title={<ExerciseTitle />} {...props}>
    <SimpleForm>
      <TextInput source="title" />
      <SelectInput source="type" choices={[
        { id: 'UPPERBODY', name: 'Upper Body' },
        { id: 'LOWERBODY', name: 'Lower Body' },
        { id: 'CORE', name: 'Core' },
        { id: 'MULTI', name: 'Multi' },
        { id: 'STRETCH', name: 'Stretch' },
        { id: 'PILATESR', name: 'Pilates R' },
      ]} />
      <RadioButtonGroupInput source="gender" choices={[
        { id: 'MALE', name: 'Male' },
        { id: 'FEMALE', name: 'Female' },
      ]} />
      <FileInput source="video">
        <FileField source="url" title="filename" />
      </FileInput>
      <TextInput fullWidth source="youtubeVideoId" title={"Youtube"} />
    </SimpleForm>
  </Edit>
);
