import React from 'react';
import { List, Datagrid, TextField, EditButton, UrlField, Filter, TextInput, SelectInput } from 'react-admin';

const ExerciseFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <SelectInput source="type" choices={[
      { id: 'UPPERBODY', name: 'Upper Body' },
      { id: 'LOWERBODY', name: 'Lower Body' },
      { id: 'CORE', name: 'Core' },
      { id: 'MULTI', name: 'Multi' },
      { id: 'STRETCH', name: 'Stretch' },
      { id: 'PILATESR', name: 'Pilates R' },
    ]} />
  </Filter>
);

export const ExerciseList = (props) => (
  <List {...props} filters={<ExerciseFilter />} bulkActionButtons={false} sort={{ field: 'title', order: 'ASC' }}>
    <Datagrid>
      <TextField source="title" />
      <TextField source="type" sortable={false} />
      <TextField source="gender" sortable={false} />
      <UrlField label="Video" source="video.url" sortable={false} />
      <EditButton />
    </Datagrid>
  </List>
);
