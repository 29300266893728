import React from 'react';
import { Create, SimpleForm, TextInput, SelectInput, FileInput, FileField, RadioButtonGroupInput } from 'react-admin';

export const ExerciseCreate = (props) => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <TextInput source="title" />
      <SelectInput source="type" choices={[
        { id: 'UPPERBODY', name: 'Upper Body' },
        { id: 'LOWERBODY', name: 'Lower Body' },
        { id: 'CORE', name: 'Core' },
        { id: 'MULTI', name: 'Multi' },
        { id: 'STRETCH', name: 'Stretch' },
        { id: 'PILATESR', name: 'Pilates R' },
      ]} />
      <RadioButtonGroupInput source="gender" choices={[
        { id: 'MALE', name: 'Male' },
        { id: 'FEMALE', name: 'Female' },
      ]} />
      <FileInput source="video">
        <FileField source="src" title="title" />
      </FileInput>
      <TextInput fullWidth source="youtubeVideoId" title={"Youtube"} />
    </SimpleForm>
  </Create>
);
